import {createRoute} from '@/velta/components/crud';
import {createRoute as createRouteFilter} from '@/velta/components/filter';

export default [
    {
        path: '/sistema/empresa/excluir-conta',
        name: 'excluir-conta',
        component: () => import('@/views/empresa/ExcluirConta.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
            pageTitle: 'Excluir a minha conta',
        },
    },
    {
        path: '/sistema/empresa/login-geral',
        name: 'login-geral',
        component: () => import('@/views/empresa/LoginGeral.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
            pageTitle: 'Acessar Sistema',
        },
    },
    {
        path: '/sistema/empresa/adminvelta',
        name: 'adminvelta',
        component: () => import('@/views/empresa/AdminVelta.vue'),
        meta: {
            pageTitle: 'Admin Velta',
            resource: 'ADMINVELTA',
            action: 'read',
        }
    },
    {
        path: '/sistema/empresa/',
        name: 'home-company',
        component: () => import('@/views/empresa/Home.vue'),
        meta: {
            pageTitle: 'Página Inicial',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'Página Inicial',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/empresa/dashboardfinanceiro',
        name: 'dashboardfinanceiro',
        component: () => import('@/views/empresa/DashboardFinanceiro.vue'),
        meta: {
            pageTitle: 'Dashboard Financeiro',
            resource: 'DASH_FINANCEIRO',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Dashboard Financeiro',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/empresa/dashboardservicos',
        name: 'dashboardservicos',
        component: () => import('@/views/empresa/DashboardServicos.vue'),
        meta: {
            pageTitle: 'Dashboard Serviços',
            resource: 'DASH_SERVICOS',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Dashboard Serviços',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/empresa/cadastroempresa',
        name: 'cadastroempresa',
        component: () => import('@/views/empresa/cadastroEmpresa/CadastroEmpresa.vue'),
        meta: {
            pageTitle: 'Cadastrar Empresa',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/buscador/:estado?/:cidade?/:servico?',
        name: 'buscador',
        component: () => import('@/views/empresa/buscador/Index.vue'),
        meta: {
            pageTitle: 'Buscador de Empresa',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/configuracoes',
        name: 'configuracoes-company',
        component: () => import('@/views/empresa/configuracoes/Form.vue'),
        meta: {
            resource: 'CAD_CONFIGURACOES',
            action: 'read',
        }
    },
    {
        path: '/sistema/empresa/login',
        name: 'login-company',
        component: () => import('@/views/empresa/Login.vue'),
        meta: {
            pageTitle: 'Acessar Sistema',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/esqueciminhasenha',
        name: 'esqueciminhasenha-company',
        component: () => import('@/views/empresa/ForgotPassword.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/termodeuso',
        name: 'termodeuso',
        component: () => import('@/views/empresa/TermoDeUso.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/politicaprivacidade',
        name: 'politicaprivacidade',
        component: () => import('@/views/empresa/PoliticaDePrivacidadeDeDados.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/empresa/nao-autorizado',
        name: 'naoAutorizado',
        component: () => import('@/views/empresa/NaoAutorizado.vue'),
        meta: {
            layout: 'full',
            resource: 'any'
        }
    },
    {
        path: '/sistema/empresa/error-404',
        name: 'error-404',
        component: () => import('@/views/empresa/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'any'
        },
    },
    {
        path: '/sistema/empresa/planosepagamentos',
        name: 'planosepagamentos',
        component: () => import('@/views/empresa/planosepagamentos/List.vue'),
        meta: {
            resource: 'any',
        }
    },
    {
        path: '/sistema/empresa/planosepagamentos/cartao',
        name: 'planosepagamentos-cartao',
        component: () => import('@/views/empresa/planosepagamentos/Cartao.vue'),
        meta: {
            resource: 'any',
        }
    },
    {
        path: '/sistema/empresa/faq',
        name: 'faq',
        component: () => import('@/views/empresa/faq/Index.vue'),
        meta: {
            resource: 'any',
        }
    },
    {
        path: '/sistema/empresa/ordemservico',
        name: 'ordemservico',
        component: () => import('@/views/empresa/ordemservico/Index.vue'),
        meta: {
            resource: 'TAR_ORDENSDESERVICO',
            action: 'read',
            navActiveLink: 'ordemservico',
        }
    },
    {
        path: '/sistema/empresa/estoque/dashboard',
        name: 'estoquedashboard',
        component: () => import('@/views/empresa/estoque/Dash.vue'),
        meta: {
            resource: 'CAD_ESTOQUE',
            action: 'read',
            navActiveLink: 'estoque',
        }
    },
    {
        path: '/sistema/empresa/configuracoes-iniciais',
        name: 'initialConfiguration',
        component: () => import('@/views/empresa/configuracoesIniciais/Index.vue'),
        meta: {
            pageTitle: 'Configurações Iniciais',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'Configurações Iniciais',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/sistema/empresa/whatsapp-suporte',
        name: 'initialConfigurationWhatsappSuporte',
        component: () => import('@/views/empresa/configuracoesIniciais/WhatsAppSuporte.vue'),
        meta: {
            pageTitle: 'Configurações Iniciais',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'Configurações Iniciais',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/sistema/empresa/black-veltacar',
        name: 'blackPromotion',
        component: () => import('@/views/empresa/promotion/Black.vue'),
        meta: {
            pageTitle: 'BlackVeltacar',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'BlackVeltacar',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/sistema/empresa/versions',
        name: 'versions',
        component: () => import('@/views/empresa/Versions.vue'),
        meta: {
            pageTitle: 'Histórico de Versões',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'Histórico de Versões',
                    active: true,
                },
            ],
        }
    },
    ...createRoute('Empresas', 'adminveltaempresas', 'ADMINVELTA'),
    ...createRoute('Usuários', 'usuario', 'CAD_USUARIOS'),
    ...createRoute('Modalidades', 'modalidade', 'CAD_MODALIDADES', 'Cadastros', true, true),
    ...createRoute('Controle de Caixa', 'controlecaixa', 'TAR_CONTROLECAIXA', 'Operacionais', true, true),
    ...createRoute('Serviços', 'servico', 'CAD_SERVICOS', 'Cadastros', true, true),
    ...createRoute('Perfis', 'perfil', 'CAD_PERFIS'),
    ...createRoute('Vagas', 'vaga', 'CAD_VAGAS', 'Cadastros', true, true),
    ...createRoute('Orçamentos', 'orcamento', 'TAR_ORCAMENTOS', 'Operacionais', true, true),
    ...createRoute('Recibos', 'recibo', 'TAR_RECIBOS'),
    ...createRoute('Sugestão de Melhoria', 'sugestaomelhoria', 'CAD_SUGESTAOMELHORIA'),
    ...createRoute('Clientes', 'cliente', 'CAD_CLIENTES'),
    ...createRoute('Nota Fiscal', 'notafiscal', 'TAR_NOTAFISCAL', 'Operacionais'),
    ...createRoute('Convênios', 'convenio', 'CAD_CONVENIOS', 'Cadastros', true, true),
    ...createRoute('Funcionários', 'funcionario', 'CAD_FUNCIONARIOS', 'Cadastros', true, true),
    ...createRoute('Produtos/Peças', 'produto', 'CAD_PRODUTOS', 'Cadastros', true, true),
    ...createRoute('Venda de Produtos/Peças', 'venda', 'CAD_VENDAPRODUTOS', 'Operacionais'),
    ...createRoute('Entradas Estoque', 'estoque', 'CAD_ESTOQUE', 'Operacionais'),
    ...createRoute('Empresa Cliente', 'empresaCliente', 'CONF_AUTOAGENDAMENTOPREENCHEREMPRESA', 'Cadastros', false, false),
    ...createRoute('Formas de Pagamento', 'formasdepagamentos', 'CAD_FORMASDEPAGAMENTOS', 'Cadastros', false, false),
    ...createRoute('Checklist Modelo', 'itemchecklistmodelo', 'CAD_ITEM_MODELO_CHECKLIST', 'Cadastros', false, false),
    ...createRoute('Categorias de Caixa', 'controlecaixacategoria', 'CAD_CONTROLE_CAIXA_CATEGORIA', 'Cadastros', false, false),
    ...createRouteFilter('Estoque', 'RelatorioEstoque', 'REL_ESTOQUE'),
    ...createRouteFilter('Relatório de Venda de Produtos/Peças', 'RelatorioVenda', 'REL_VENDA'),
    ...createRouteFilter('Clientes', 'RelatorioCliente', 'CAD_CLIENTES'),
    ...createRouteFilter('Caixa', 'Caixa', 'REL_CAIXA'),
    ...createRouteFilter('Aniversariantes', 'Aniversariante', 'REL_ANIVERSARIANTES'),
    ...createRouteFilter('Serviços Realizados', 'ServicoRealizado', 'REL_SERVICOSREALIZADOS'),
]
